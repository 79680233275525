/* eslint-disable react/no-unescaped-entities */
import React from "react"
import styled from "@emotion/styled"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { breakPoints, toRem } from "../styles/utils"
import Header from "../components/header"
import Wrapper from "../components/wrapper"
import Footer from "../components/footer"
import SEO from "../components/seo"
import Block from "../components/block"

const ProfilePicContainer = styled.div`
  background-color: #152210;
  margin-bottom: ${toRem(30)};
  overflow: hidden;
  height: 40vh;

  .profile-pic {
    height: 50vh;
  }

  @media (min-width: ${breakPoints.small}) and (max-width: ${breakPoints.smallMedium}) {
    margin-bottom: ${toRem(30)};
    height: 80vh;

    .profile-pic {
      height: auto;
    }
  }

  @media (min-width: ${breakPoints.medium}) {
    margin-bottom: ${toRem(80)};
    margin-top: ${toRem(5)};
    height: 75vh;

    .profile-pic {
      height: 86vh;
    }
  }

  @media (min-width: ${breakPoints.small}) and (max-width: ${breakPoints.mediumLarge}) {
    margin-bottom: ${toRem(30)};
    height: auto;

    .profile-pic {
      height: auto;
    }
  }

  @media (min-width: ${breakPoints.medium}) {
    margin-bottom: ${toRem(80)};
  }
`

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      profileImage: file(relativePath: { eq: "profilepic-bahaykubo-3.jpg" }) {
        ...imageLargeHeroProp
      }
      seoImage: file(relativePath: { eq: "profilepic-bahaykubo-3.jpg" }) {
        ...imageSEOProp
      }
      fileName: file(relativePath: { eq: "resume-oscarbicadaiii.pdf" }) {
        publicURL
      }
    }
  `)

  const description =
    "Hello! I'm Oscar Bicada III, a designer raised and currently living in Bohol. I've been designing for 14 years, half of that in the digital space, and it led me to have an immense..."

  return (
    <Wrapper>
      <SEO
        title="About"
        image={data.seoImage.childImageSharp.resize.src}
        description={description}
      />
      <Header />
      <main id="MainContent">
        <ProfilePicContainer>
          <Img
            backgroundColor="#152210"
            className="profile-pic"
            placeholderClassName="gatsby-image-placeholder"
            fluid={data.profileImage.childImageSharp.fluid}
          />
        </ProfilePicContainer>
        <Block>
          <p>
            Hello! I'm Oscar Bicada III, a designer raised and currently living in Bohol. I've been
            designing for 14 years, half of that in the digital space, and it led me to have an
            immense interest in user interface design. Having a common understanding of design
            principles has equipped me and keen to work on interface visual and interaction design
            challenges at varying degrees. Besides that, I do contextual illustrations in and around
            it. And more importantly, I strive for clear, concise, sustainable designs that people
            understand.
          </p>
          <p>
            Previously lead designer at <OutboundLink href="https://sci.ph">SCI</OutboundLink>, a
            startup fintech company in Manila. One of the best life choices I've made was joining
            this company in 2014. Working with talented individuals has a significant impact on me,
            even though most of my days spent remotely. I learned a lot from them, and it shapes me
            into who I'm today. And I'm forever grateful for the experience.
          </p>
          <p>
            Before fintech, I started working for a multimedia company as a graphic designer and
            then at an advertising agency as an art director. Two years later, together with
            talented individuals, we partnered up to start a digital design agency,{" "}
            <OutboundLink href="https://milkfishstudio.com">Milkfish Studio</OutboundLink>. We do
            creative branding for print and web.
          </p>
          <p>
            At free time, I spend playing with my son. With prior knowledge of web development, I
            have recently been tinkering with React and discovered Gatsby, which led me to build
            this website.
          </p>
          <p>
            For a general inquiry, feel free to get in touch at{" "}
            <OutboundLink href="mailto:hello@oooscars.com">hello@oooscars.com</OutboundLink>.
          </p>
          <p>
            If you are looking for more relevant information,{" "}
            <a href={data.fileName.publicURL}>here's my resume</a>.
          </p>
        </Block>
      </main>
      <Footer />
    </Wrapper>
  )
}

export default About
